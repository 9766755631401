(function ($) {

    /**
     * Inicjalizacja podstawowych zdarzeń.
     */
    if ($('.js-contact-form').length > 0) {
        $('.js-contact-form').submit(function (e) {
            e.preventDefault();

            if (!checkForm()) {
                return false;
            } else {
                sendEmail($(this));                
            };
        });
        $('.js-contact-input').on('focusout', function () {
            if ($('.js-contact-input').parent().hasClass('has-error')) {
                checkForm();
            }
        });
    }

    /**
     * Funkcja walidująca cały formularz kontaktowy.
     * @version 0.1.0
     * @returns true - puszcza formularz dalej, false - zatrzymuje formularz (anuluje).
     */
    function checkForm () {
        let error = false;

        $('.js-contact-form').find($('.form-group')).each(function(index) {
            let input = $(this).find('.js-contact-input'),
                inputType = input.attr('type');

            if (input.is("textarea")) {
                inputType = 'textarea';
            }

            switch (inputType) {
                case 'textarea':
                case 'text':
                case 'email':
                    if (input.val() == '') {
                        error = true;
                        $(this).addClass('has-error');
                        $(this).find('.js-input-error-message').addClass('specialists-contact__input-error-message--show');
                    } else {
                        $(this).removeClass('has-error');
                        $(this).find('.js-input-error-message').removeClass('specialists-contact__input-error-message--show');
                    }
                    break;

                case 'checkbox':
                    if (!input.is(':checked')) {
                        error = true;
                        $(this).addClass('has-error');
                        $(this).find('.js-input-error-message').removeClass('specialists-contact__input-error-message--hide');
                        $(this).find('.js-input-error-message').addClass('specialists-contact__input-error-message--show');
                    } else {
                        $(this).removeClass('has-error');
                        $(this).find('.js-input-error-message').addClass('specialists-contact__input-error-message--hide');
                        $(this).find('.js-input-error-message').removeClass('specialists-contact__input-error-message--show');
                    }
                    break;
            }
        });

        if (error) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Funkcja odpowiadająca za wysłanie maila.
     * @param {*} form
     * @version 0.1.0
     */
    function sendEmail (form) {
        let data = form.serializeArray().reduce(function(obj, item) {
                obj[item.name] = item.value;
                return obj;
            }, {}),
            sendButton = $('.js-send-button');

        $.ajax({
            url: ajax_url,
            type: 'POST',
            data: {
                'action': 'send_map_contact_mail',
                'name': data.name,
                'mail': data.mail,
                'message': data.message,
            },
            beforeSend: function () {
                sendButton.text('Czekaj');
                sendButton.prop('disabled', true);
            },
            success: function (response) {
                if (response == 'OK') {
                    sendButton.text('Gotowe');
                    $('.js-mail-sent').addClass('specialists-contact__success-alert--show');
                } else {
                    sendButton.text('Błąd');
                    console.error(response);
                }
            },
            error: function (xhr, error) {
                console.error(error);
            }
        });
    }

})(jQuery);
