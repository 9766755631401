(function ($) {
    /**
     * Inicjalizacja podstawowych zdarzeń.
     */
    $('[data-toggle="tooltip"]').tooltip();
    onKonamiCode();
    atmMover();

    /**
     * Konami Code ^.^
     * @version 1.0.0
     */
    function onKonamiCode() {
        var input = '';
        var key = '38384040373937396665';
        document.addEventListener('keydown', function (e) {
            input += ("" + e.keyCode);
            if (input === key) {
                console.log('\o/');
            }
            if (!key.indexOf(input)) return;
            input = ("" + e.keyCode);
        });
    }

    /**
     * Wrzucanie ATODERMLANDII na koniec menu na mobile.
     * @version 1.0.0
     */
    function atmMover() {
        if (window.outerWidth < window.outerHeight) {
            let menu = $('#menu-menu-glowne');
                menu.find('.atm').appendTo(menu)
        }
    }

})(jQuery);
