/**
 * TRYB DEVELOPERSKI
 * Włącza się automatycznie tylko na localhost ^.^
 */
var devMode = false;
switch (location.hostname) {
    case "localhost":
    case "127.0.0.1":
    case "zsercemoatopii.local":
    case "zsercemoatopii.test":
        devMode = true;
        break;
}

/**
 * Dodatkowe grafiki do map (np. customowe znaczniki).
 */
var images = {
    pinBlur: tpl_url + '/dist/img/map/map-blur.png',
    pinDirect: {
        psychologist: tpl_url + '/dist/img/map/map-pin-blue.png',
        pharmacy: tpl_url + '/dist/img/map/map-pin-dark-blue.png',
        doctor: tpl_url + '/dist/img/map/map-pin-pink.png',
        other: tpl_url + '/dist/img/map/map-pin-other.png',
    },
}

/**
 * Funkcja sortująca placówki rosnąco po dystansie.
 * @param {object} a
 * @param {object} b
 * @version 1.0.0
 */
function sortPlaces(a, b) {
    if (a.text.distance < b.text.distance){
      return -1;
    }
    if (b.text.distance < a.text.distance){
      return 1;
    }
    return 0;
}

/**
 * Funkcja pomocnicza dla filtrowania po kategoriach.
 * @param {string} needle 
 * @param {array} haystack 
 * @version 1.0.0
 */
function inArray(needle, haystack) {
    let length = haystack.length;
    for (let i = 0; i < length; i++) {
        if (haystack[i] == needle) {
            return true;
        }
    }
    return false;
}

var appContainer = document.getElementById('mapApp');

if (appContainer !== null) {
    Vue.config.devtools = devMode;

    var mapData = $('#mapContainer');
    var mapApp = new Vue({
        el: '#mapApp',
        data: {
            mapId: 'map',
            lat: 52.40,
            lng: 16.93,
            zoom: 15,           // Domyślny startowy zoom
            map: null,
            infowindow: '',
            cityName: '',
            searchingCity: false,   // Czy wyszukujemy miasto (Poznań, Warszawa, etc...)
            filters: [],        // Aktualnie zastosowane filtry
            distanceCircle: 20,  // Odległość w jakim obrębie szukać placówek
            allPlaces: [],      // Trzyma wszystkie miejsca niezależnie od filtrów
            actualPlaces: [],   // Trzyma TYLKO aktualnie wyświetlane miejsca
            mapOverlay: true,   // Ukrywanie i pokazywanie loadera na mapie
            mapError: false,
            mapActive: false,   // Ukrywanie i pokazywanie sekcji mapy/promo
            searchLock: false,  // Blokowanie pola wyszukiwarki na czas działania skryptu
            markerCluster: null,                                    // Google Maps - Trzyma markery z mapy
            directionsDisplay: new google.maps.DirectionsRenderer({ // Google Maps - Trzyma wyznaczone trasy z mapy
                preserveViewport: true,
            }),
        },
        mounted: function() {
            const ref = this;

            ref.getPointersLocations();

            /* Dodatkowy bind dla uruchomienia funkcji showRoute (Vue) z wewnątrz kontenera Google Maps */
            window.showRoute = function (lat, lng) {
                ref.showRoute(lat, lng);
            }

            ref.initMap();
        },
        computed: {
            rawMarkers: function () {
                let rawMarkers = [];

                // Przygotowanie markerów oraz merge duplikatów.
                for (i = 0; i < this.actualPlaces.length; i++) {
                    rawMarkers[i] = {
                        category: this.actualPlaces[i].category,
                        id: this.actualPlaces[i].id,
                        lat: this.actualPlaces[i].lat,
                        lng: this.actualPlaces[i].lng,
                        text: [
                            this.actualPlaces[i].text
                        ]
                    };
                }
                return rawMarkers;
            }
        },
        updated: function () {
            $resultsScrollBox = $('.js-map-panel-scroller');
            if ($resultsScrollBox.length > 0) {
                $resultsScrollBox.mCustomScrollbar('destroy');
                $resultsScrollBox.mCustomScrollbar({
                    theme: 'zsoa'
                });
            }
        },
        watch: {
            'zoom': function(to, from) {
                const ref = this;
                ref.map.setZoom(to);
            },

            /* Szukamy miasta przy odświeżeniu nazwy (powoduje sporo requestów, ale inaczej się nie dało ;E) */
            'cityName': {
                handler: function () {
                    const ref = this;
                    if (2 <= ref.cityName.length) { // Najkrótrza nazwa miejscowości w PL - 'Oś'
                        ref.findCity();
                    }
                },
            },

            /*
             * Na mobile zmniejsza wielkość kontenera z listą lokalizacji jeśli jest ich za mało.
             * W przypadku braku wyników ukrywamy sekcję scrollera pozostawiając tym samym tylko
             * info "WYNIKI WYSZUKIWANIA (0)".
             */
            'actualPlaces': {
                handler: function () {
                    const ref = this;

                    if (window.outerWidth < window.outerHeight) {
                        let scrollBox = $('.js-map-panel-scroller');

                        if (ref.actualPlaces.length <= 2) {
                            scrollBox.addClass('specialists-map__panel-scroller--smaller');
                        } else {
                            scrollBox.removeClass('specialists-map__panel-scroller--smaller');
                        }
                    }

                    if (ref.actualPlaces.length == 0) {
                        $('.js-map-panel-scroller').addClass('specialists-map__panel-scroller--hide');
                    } else {
                        $('.js-map-panel-scroller').removeClass('specialists-map__panel-scroller--hide');
                    }
                }
            },

            /* W przypadku filtru "Realizacja kuponów" wywalamy oraz blokujemy wszystkie inne (blokady jako :disabled) */
            'filters': {
                handler: function () {
                    const ref = this;

                    if (ref.filters.indexOf('db-pharmacies-coupon') != -1 && 1 < ref.filters.length) {
                        ref.filters = ['db-pharmacies-coupon'];
                    }
                },
                deep: true
            },
        },
        methods: {

            /**
             * Inicjalizacja mapy
             * @version 1.0.0
             */
            initMap: function() {
                const ref = this;
                let center = {
                    lat: ref.lat,
                    lng: ref.lng
                },
                mapCont = document.getElementById(ref.mapId);

                if (typeof(mapCont) === 'object') {
                    ref.map = new google.maps.Map(mapCont, {
                        minZoom: 6,
                        maxZoom: 20,
                        zoom: ref.zoom,
                        center: center,
                        disableDefaultUI: true,
                        scrollwheel: true,
                    });
                }

                google.maps.event.addDomListener(ref.map, 'zoom_changed', function() {
                    ref.zoom = ref.map.getZoom();
                });
            },

            /**
             * Pobieranie danych o placówkach
             * @version 1.0.0
             */
            getPointersLocations: function() {
                const ref = this;

                axios.all([
                    axios.get(base_url + '/wp-json/wp/v2/db-psychologysts'),
                    axios.get(base_url + '/wp-json/wp/v2/db-doctors'),
                    axios.get(base_url + '/wp-json/wp/v2/db-pharmacies'),
                ]).then(axios.spread( function(apiDataA, apiDataB, apiDataC) {
                    ref.allPlaces = $.merge(apiDataA.data, $.merge(apiDataB.data, apiDataC.data));
                    ref.mapOverlay = false;

                    if (ref.mapActive == true) {
                        ref.filterData();
                    }
                }))
                .catch(function (error) {
                    ref.mapOverlay = false;
                    ref.mapError = true;
                    console.error('Axios Err: ', error);
                });
            },

            /**
             * Funkcja odpowiada za zarządzanie filtrami i przekazywaniem danych do wyświetlenia.
             * @version 1.1.0
             */
            filterData: function () {
                const ref = this;

                if (ref.cityName != '' && 0 <= ref.distanceCircle && ref.distanceCircle <= 100) {
                    ref.searchLock = true;

                    let distanceCircleMeters = ref.distanceCircle * 1000;

                    ref.actualPlaces = []; // Czyścimy tablicę aktualnych lokalizacji.
                    ref.directionsDisplay.setMap(null); // Czyścimy wyznaczone trasy.

                    ref.allPlaces.forEach(function(place) {

                        // Filtrujemy dane wg zaznaczonych filtrów.
                        if (ref.filters.length === 0
                         || inArray(place.type, ref.filters) && !inArray('db-pharmacies-coupon', ref.filters)
                         || inArray('db-pharmacies-coupon', ref.filters) && place.type == 'db-pharmacies' && place.acf.coupon_zsoa == true
                        ) {
                            let id = parseInt(place.id), 
                                lat = parseFloat(place.acf.coordinates.lat),
                                lng = parseFloat(place.acf.coordinates.lng);

                            if (!isNaN(lat) || !isNaN(lng)) {
                                let distance = ref.getDistance([ref.lat, ref.lng], [lat, lng]),
                                    category = '',
                                    city = place.acf.city.replace(/\d{2}-\d{3}\s/, '').toLowerCase(), // Wywalamy kod pocztowy z pola 'city'
                                    cityName = ref.cityName.replace(/,.*/, '').toLowerCase(); // Wywalamy wszystko co po ',' w wyszukiwanej frazie (przypuszczalnie ', Polska').

                                // W zależności od tego czy user wyszukuje miasto, stosujemy inne zasady fitrowania!
                                if (
                                    ref.searchingCity == false && distance <= distanceCircleMeters
                                 || ref.searchingCity == true && (city == cityName || distance <= distanceCircleMeters)
                                 ) {

                                    if (ref.searchingCity == true) {
                                        ref.map.setZoom(11);
                                    }

                                    switch (place.type) {
                                        case 'db-pharmacies':
                                            category = 'pharmacy';
                                            break;
                                        case 'db-doctors':
                                            category = 'doctor';
                                            break;
                                        case 'db-psychologysts':
                                            category = 'psychologist';
                                            break;
                                    }

                                    ref.actualPlaces.push({
                                        id: id,
                                        idMerged: id,
                                        lat: lat,
                                        lng: lng,
                                        text: {
                                            distance: distance,
                                            name: place.acf.name,
                                            placeName: place.acf.place_name,
                                            street: place.acf.street,
                                            city: place.acf.city,
                                            phone: place.acf.phone,
                                            website: place.acf.website,
                                            nfz: place.acf.nfz,
                                            coupon: place.acf.coupon_zsoa,
                                        },
                                        category: category,
                                    });                                    
                                }
    
                            }
                        }
                    });

                    ref.actualPlaces.sort(sortPlaces);

                    ref.searchLock = false;
                    ref.setMarkers();

                    if (ref.mapOverlay == false) {
                        ref.findCity([ref.lat, ref.lng]);
                    }

                    ref.fitToMarkers();

                    ref.mapActive = true;
                } else {
                    $('.js-search-specialists-input').focus();
                }
            },

            /**
             * Inicjalizacja markerów.
             * @version 3.1.0
             */
            setMarkers: function() {
                const ref = this;

                ref.infowindow = new google.maps.InfoWindow();

                let rawMarkers = ref.rawMarkers;
                    markers = [],
                    markerType = images.pinDirect.psychologyst;

                // Mergujemy duplikaty
                for (i = 0; i < rawMarkers.length; i++) {
                    const element = rawMarkers[i];

                    // Sprawdzamy czy taki punktor istnieje. Jeżeli istnieje
                    // to łączymy content do istniejącego i wywalamy duplikat z tablicy + dopisujemy idMerged.
                    for (let index = 0; index < rawMarkers.length; index++) {
                        let elementNew = rawMarkers[index];

                        if (element != null && elementNew != null) {
                            if (elementNew.lat == element.lat && elementNew.lng == element.lng && i != index) {
                                element.text.push(elementNew.text[0]);
                                ref.actualPlaces[index].idMerged = element.id;
                                rawMarkers[index] = null;
                            }
                        }
                    }

                    // Modyfikujemy odległości
                    if (element != null) {
                        for (let index = 0; index < element.text.length; index++) {
                            if (element.text[index].distance/1 == element.text[index].distance) { // is_number
                                if (1000 < element.text[index].distance) {
                                    element.text[index].distance = Math.round((element.text[index].distance/1000)*100)/100 + ' km';
                                } else {
                                    element.text[index].distance = element.text[index].distance + ' m';
                                }
                            }
                        }
                    }
                }

                // Konfigurujemy każdy punktor
                for (i = 0; i < rawMarkers.length; i++) {
                    if (rawMarkers[i] != null) {
                        switch (rawMarkers[i].category) {
                            case 'pharmacy':
                                markerType = images.pinDirect.pharmacy;
                                break;
                            case 'doctor':
                                markerType = images.pinDirect.doctor;
                                break;
                            case 'psychologist':
                                markerType = images.pinDirect.psychologist;
                                break;
                            default:
                                markerType = images.pinDirect.other;
                                break;
                        }

                        marker = new google.maps.Marker({
                            position: new google.maps.LatLng(rawMarkers[i].lat, rawMarkers[i].lng),
                            icon: markerType,
                            id: rawMarkers[i].id,
                            text:  rawMarkers[i].text
                        });

                        google.maps.event.addListener(marker, 'click', (function(marker, i) {
                            return function() {

                                let trueName = '',
                                    contentString = ''
                                    + '<div class="specialists-map__pointer-info">'
                                        + '<p class="specialists-map__pointer-info-text specialists-map__pointer-info-text--distance">' + marker.text[0].distance +'</p>';

                                for (let i = 0; i < marker.text.length; i++) {
                                    const element = marker.text[i];
                                    trueName =  element.name != '' ? element.name : element.placeName;
                                    if (0 < i) {
                                        contentString += '<hr class="specialists-map__pointer-info-spacer">';
                                    }
                                    contentString += ''
                                        + '<p class="specialists-map__pointer-info-text specialists-map__pointer-info-text--title">' + trueName + '</p>'
                                        + '<p class="specialists-map__pointer-info-text specialists-map__pointer-info-text--address-top">' + element.street + '</p>'
                                        + '<p class="specialists-map__pointer-info-text specialists-map__pointer-info-text--address-bottom">' + element.city + '</p>';
                                }

                                contentString += ''
                                    + '<button class="specialists-map__pointer-info-button" onclick="showRoute(' + marker.position.lat() + ', ' + marker.position.lng() + ')">Zobacz trasę</button>'
                                    + '<a href="https://www.google.com/maps/dir/?api=1&origin=' + ref.lat + ',' + ref.lng + '&destination=' + marker.position.lat() + ',' + marker.position.lng() + '" target="_blank" rel="nofollow" class="specialists-map__pointer-info-button">Wyznacz trasę w Google</button>'
                                + '</div>';

                                ref.infowindow.setContent(contentString);
                                ref.infowindow.open(map, marker);

                                ref.map.setCenter(marker.getPosition());
                                ref.offsetCenter(ref.map.getCenter());
                            }
                        })(marker, i));
                        markers.push(marker);
                    }

                    // Ustawienia Cluster'a
                    let defaultStyles = {
                            textColor: '#fff',
                            url: images.pinBlur,
                            height: 40,
                            width: 40,
                            textSize: 18
                        },
                        clusterDefaultStyles = [
                            defaultStyles,
                            defaultStyles,
                            defaultStyles
                        ],
                        mcOptions = {
                            gridSize: 40,
                            maxZoom: 13,
                            zoomOnClick: true,
                            averageCenter: true,
                            styles: clusterDefaultStyles,
                        };

                    // Usuwamy markery/clustry z mapy i dodajemy nowe markery/clustry
                    if (ref.markerCluster != null) {
                        ref.markerCluster.clearMarkers();
                    }
                    ref.markerCluster = new MarkerClusterer(ref.map, markers, mcOptions);
                }

                // W tybie developerskim rysujemy kółka :3
                // if (devMode) {
                //     var antennasCircle = new google.maps.Circle({
                //         strokeColor: "#FF0000",
                //         strokeOpacity: 0.8,
                //         strokeWeight: 2,
                //         fillColor: "#FF0000",
                //         fillOpacity: 0.35,
                //         map: ref.map,
                //         center: {
                //             lat: ref.lat,
                //             lng: ref.lng
                //         },
                //         radius: ref.distanceCircle * 1000,
                //     });
                // }
            },

            /**
             * Funkcja odpowiada za znalezienie miasta na mapie i ustalenie jego koordynatów.
             * W adresie podmienia w locie kody pocztowe wg schematu: '62041/62-041 => 62-041 PL'.
             * Dodatkowo poprzez podanie parametru 'forceLocation' można wymusić ustawienie konkretnej
             * lokalizacji po koordynatach.
             * @param {array} forceLocation - opcjonalna tablica zawierająca koordynaty [lat, lng]
             * @param {boolean} zoom - opcjonalna zmienna definiująca, czy przy wymuszeniu lokalizacji ustawić też konkretny zoom
             * @param {boolean} openInfoWindow - opcjonalny argument otwierający dymek do pina
             * @param {boolean} markerID - opcjonalny argument zawierający ID klikniętego pina (wymagane przy openInfoWindow)
             * @see https://developers.google.com/maps/documentation/geocoding/intro
             * @version 2.0.0
             */
            findCity: function(forceLocation, zoom, openInfoWindow, markerID) {
                const ref = this;

                if (!zoom) {
                    zoom = false;
                }
                if (!openInfoWindow) {
                    openInfoWindow = false;
                    markerID = null;
                }

                if (forceLocation) {
                    ref.map.setCenter({
                        lat: forceLocation[0],
                        lng: forceLocation[1]
                    });
                    ref.offsetCenter(ref.map.getCenter());

                    if (zoom && ref.map.getZoom() < 15) {
                        ref.map.setZoom(15);
                    }
                    if (openInfoWindow) {
                        // Jedziemy po wszystkich clustrach, i patrzymy czy nasz pin nie jest wewnątrz clustra.
                        // Jeśli jest, to zrobimy większy zoom żeby wyciągnąć go z clustra.
                        for (let index = 0; index < ref.markerCluster.clusters_.length; index++) {
                            const element = ref.markerCluster.clusters_[index];

                            if (element.markers_ != undefined) {
                                if (2 <= element.markers_.length) {
                                    for (let i = 0; i < element.markers_.length; i++) {
                                        const marker = element.markers_[i];

                                        // Ten marker jest wewnątrz CLUSTRA
                                        if (marker.id == markerID) {
                                            ref.map.setZoom(18);
                                            ref.map.setCenter({
                                                lat: forceLocation[0],
                                                lng: forceLocation[1]
                                            });
                                            ref.offsetCenter(ref.map.getCenter());
                                            break; // Kończymy pętlę, ponieważ już znaleźliśmy to czego szukamy ;]
                                        }
                                    }
                                }
                            }
                        }

                        // Jedziemy po wszystkich ustawionych markerach i szukamy tego, który ma otworzyć dymek
                        for (let index = 0; index < ref.markerCluster.markers_.length; index++) {
                            const element = ref.markerCluster.markers_[index];

                            if (element.id == markerID) {
                                    google.maps.event.trigger(element, 'click');
                                break; // Kończymy pętlę, ponieważ już znaleźliśmy to czego szukamy ;]
                            }
                        }
                    }
                } else {
                    if (ref.cityName != '') {
                        let geocoder = new google.maps.Geocoder(),
                            localCityName = ref.cityName;

                        localCityName = localCityName.replace(/(\d\d-\d\d\d)/gi, function (match) {
                            return match[0] + match[1] + match[2] + match[3] + match[4] + match[5] + ' PL';
                        });

                        localCityName = localCityName.replace(/(\d\d\d\d\d)/gi, function (match) {
                            return match[0] + match[1] + '-' + match[2] + match[3] + match[4] + ' PL';
                        });

                        geocoder.geocode({'address': localCityName}, function(results, status) {
                            if (status == 'OK') {
                                ref.lat = results[0].geometry.location.lat();
                                ref.lng = results[0].geometry.location.lng();

                                // Definiujemy, czy znalezisko to miescowość, czy może coś innego (ulica, kraj, etc...)
                                if (inArray('locality', results[0].types)) {
                                    console.log('%cZSzukany adres to nazwa miejscowości!', 'padding: .2em .6em .3em; background-color: #5cb85c; font-weight: 700; color: #fff; text-align: center; border-radius: .25em;');
                                    ref.searchingCity = true;
                                } else {
                                    console.log('%cSzukany adres to coś innego niż miejscowość!', 'padding: .2em .6em .3em; background-color: #d9534f; font-weight: 700; color: #fff; text-align: center; border-radius: .25em;');
                                    ref.searchingCity = false;
                                }

                            } else {
                                console.error('findCity: ', status);
                                /* // Nie pokazywać błędu dopóki geocoder wysyłą request przy każdej zmianie pola cityName! ;c
                                swal({
                                    icon: 'error',
                                    title: 'Coś poszło nie tak...',
                                    text: 'Geocoder zwrócił błąd: ' + status,
                                }); */
                            }
                        });
                    }
                }
            },

            /**
             * Funkcja odpowiada za automatyczne ustalenie lokalizacji user'a.
             * @see https://developers.google.com/maps/documentation/geocoding/intro#reverse-example
             * @version 1.0.2
             */
            findLocation: function () {
                const ref = this;

                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function (position) {
                        let latlang = {
                                lat: parseFloat(position.coords.latitude),
                                lng: parseFloat(position.coords.longitude)
                            },
                            geocoder = new google.maps.Geocoder;

                        if (typeof latlang.lat === 'number' && typeof latlang.lng === 'number') {
                            geocoder.geocode({
                                'location': latlang
                            }, function (results, status) {
                                if (status === 'OK' && results[0]) {
                                    ref.cityName = results[0].formatted_address;
                                    ref.lat = latlang.lat;
                                    ref.lng = latlang.lng;
                                    return true;
                                } else {
                                    console.error('Geocoder Error: ', status);
                                    swal({
                                        icon: 'error',
                                        title: 'Coś poszło nie tak...',
                                        text: 'Geocoder zwrócił następujący błąd: ' + status,
                                    });
                                }
                            });
                        } else {
                            console.error('Geocoder Error: ', status);
                            swal({
                                icon: 'error',
                                title: 'Coś poszło nie tak...',
                                text: 'Wygląda na to, że dane lokalizacyjne są nieprawidłowe...',
                            });
                        }
                    }, function (response) {
                        swal({
                            icon: 'error',
                            title: 'Coś poszło nie tak...',
                            text: 'Nie udało się znaleźć lokalizacji z powodu następującego błedu:\n' + response.message,
                        });
                    }, {
                        timeout: 10000
                    });
                } else {
                    console.error('Geocoder Error: ', status);
                    swal({
                        icon: 'error',
                        title: 'Coś poszło nie tak...',
                        text: 'Wygląda na to, że Twoja przeglądarka nie obsługuje geolokalizacji...',
                    });
                }
                return false;
            },

            /**
             * Funkcja zwracająca odległość pomiędzy punktami.
             * @param {array} p1 - tablica zawierająca koordynaty [lat, lng]
             * @param {array} p2 - tablica zawierająca koordynaty [lat, lng]
             * @see https://developers.google.com/maps/documentation/javascript/reference/geometry
             * @version 1.0.0
             */
            getDistance: function (p1, p2) {
                let distance = google.maps.geometry.spherical.computeDistanceBetween (
                    new google.maps.LatLng(p1[0], p1[1]),
                    new google.maps.LatLng(p2[0], p2[1])
                );
                distance = Math.floor(distance);
                return distance; // Zwracamy dystans w metrach
            },

            /**
             * Funkcja odpowiada za lekkie przesunięcie mapy tak, aby 'centrum" nie znajdowało się na środku, ale lekko po prawej.
             * @param {object} latlng - długość i szerokość geograficzna
             * @version 1.0.0
             */
            offsetCenter: function(latlng) {
                const ref = this;
                
                let offsetX = 250,
                    offsetY = 0,
                    scale = Math.pow(2, ref.map.getZoom()),
                    worldCoordinateCenter = ref.map.getProjection().fromLatLngToPoint(latlng),
                    pixelOffset = new google.maps.Point((offsetX/scale) || 0,(offsetY/scale) ||0),
                    worldCoordinateNewCenter = new google.maps.Point(
                        worldCoordinateCenter.x - pixelOffset.x,
                        worldCoordinateCenter.y + pixelOffset.y
                    ),
                    newCenter = ref.map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);
                ref.map.setCenter(newCenter);
            },

            /**
             * Funkcja odpowiada za wyświetlenie trasy dojazdu do danego miejsca.
             * @param {float} lat - szerokość geograficzna celu podróży
             * @param {float} lng - długość geograficzna celu podróży
             * @version 1.0.1
             */
            showRoute: function (lat, lng) {
                const ref = this;
                let directionsService = new google.maps.DirectionsService,
                    originLatLng = new google.maps.LatLng(ref.lat, ref.lng),
                    destinationLatLng = new google.maps.LatLng(lat, lng);

                ref.directionsDisplay.setMap(null);
                ref.directionsDisplay.setMap(ref.map);

                directionsService.route({
                    origin: originLatLng,
                    destination: destinationLatLng,
                    travelMode: 'DRIVING'
                }, function (response, status) {
                    if (status === 'OK') {
                        ref.directionsDisplay.setDirections(response);
                    } else {
                        console.error('showRoute: ', status);
                        swal({
                            icon: 'error',
                            title: 'Coś poszło nie tak...',
                            text: 'Nie udało się wyświetlić wskazówek dojazdu: ' + status,
                        });
                    }
                });

                /**
                 * Event listener dla zmiany wskazówek dojazdu na mapach Google.
                 * Odpowiada za poprawne wycentrowanie mapy na trasie.
                 */
                google.maps.event.clearListeners(ref.directionsDisplay, 'directions_changed');
                google.maps.event.addListener(ref.directionsDisplay, 'directions_changed', function () {
                    let bounds = new google.maps.LatLngBounds();
                    bounds = ref.directionsDisplay.directions.routes[0].bounds;
                    bounds.extend(originLatLng);
                    bounds.extend(destinationLatLng);
                    ref.map.fitBounds(bounds);
                });
            },

            /**
             * Funkcja dopasowująca aktualny widok do skrajnych pinów na mapie.
             * @version 1.0.0
             */
            fitToMarkers: function () {
                const ref = this;

                if (ref.mapActive == true) {
                    let bounds = new google.maps.LatLngBounds(),
                        points = [
                            [ref.lat, ref.lng], // /\
                            [ref.lat, ref.lng], // <--
                            [ref.lat, ref.lng], // -->
                            [ref.lat, ref.lng], // \/
                        ];

                    // Szukamy skrajnych punktów.
                    for (let index = 0; index < ref.actualPlaces.length; index++) {
                        const element = ref.actualPlaces[index];

                        if (points[0][0] < element.lat) {
                            points[0] = [element.lat, element.lng];
                        }
                        if (points[1][1] < element.lng) {
                            points[1] = [element.lat, element.lng];
                        }
                        if (element.lng < points[2][1]) {
                            points[2] = [element.lat, element.lng];
                        }
                        if (element.lat < points[3][0]) {
                            points[3] = [element.lat, element.lng];
                        }
                    }

                    // Konwertujemy skrajne punkty na obiekty GMaps Points
                    points = [
                        new google.maps.LatLng(points[0][0], points[0][1]),
                        new google.maps.LatLng(points[1][0], points[1][1]),
                        new google.maps.LatLng(points[2][0], points[2][1]),
                        new google.maps.LatLng(points[3][0], points[3][1])
                    ];

                    // Dodajemy skrajne punkty (GMaps Points) do bounds'a.
                    for (let index = 0; index < points.length; index++) {
                        bounds.extend(points[index]);
                    }

                    ref.map.fitBounds(bounds);
                    ref.offsetCenter(ref.map.getCenter());
                }
            },
        }
    });
}
